







































































import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Component, Emit, Prop } from "vue-property-decorator";
import { AuthInfo } from "@/models/user";

@Component({})
@Translatable("usermanagement.employees")
export default class AuthsDetails extends mixins<TranslatableComponent>() {
  @Prop({ required: true })
  private authsData!: AuthInfo;

  @Emit()
  private resetOtp() {
    return;
  }

  @Emit()
  private deleteCredentials() {
    return;
  }
}
